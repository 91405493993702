import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Slider,
  Typography,
  Button,
  Grid,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { Add, Delete, FileCopy, Upload } from '@mui/icons-material';

interface SubValue {
  name: string;
  weight: number;
  value: string;
}

interface Value {
  name: string;
  weight: number;
  value: string;
  subValues: SubValue[];
}

const Calculator: React.FC = () => {
  const [values, setValues] = useState<Value[]>([]);
  const [warning, setWarning] = useState<string>('');
  const [clipboardCopied, setClipboardCopied] = useState(false);
  const [importDialogOpen, setImportDialogOpen] = useState(false);
  const [importText, setImportText] = useState<string>('');

  const addValue = () => {
    setValues([...values, { name: '', weight: 50, value: '', subValues: [] }]);
  };

  const addSubValue = (index: number) => {
    const updatedValues = [...values];
    updatedValues[index].subValues.push({ name: '', weight: 50, value: '' });
    setValues(updatedValues);
  };

  const removeValue = (index: number) => {
    setValues(values.filter((_, i) => i !== index));
  };

  const removeSubValue = (valueIndex: number, subIndex: number) => {
    const updatedValues = [...values];
    updatedValues[valueIndex].subValues = updatedValues[valueIndex].subValues.filter((_, i) => i !== subIndex);
    setValues(updatedValues);
  };

  const handleChange = <T extends keyof Value>(
    index: number,
    field: T,
    value: Value[T]
  ) => {
    const updatedValues = [...values];
    updatedValues[index][field] = value;
    setValues(updatedValues);
  };

  const handleSubChange = <T extends keyof SubValue>(
    valueIndex: number,
    subIndex: number,
    field: T,
    value: SubValue[T]
  ) => {
    const updatedValues = [...values];
    updatedValues[valueIndex].subValues[subIndex][field] = value;
    setValues(updatedValues);
  };

  const calculateParentValue = (index: number): string => {
    const parentValue = values[index];
    if (parentValue.subValues.length === 0) {
      return parentValue.value;
    }

    let totalWeight = 0;
    let weightedSum = 0;

    parentValue.subValues.forEach((sub) => {
      const weight = sub.weight / 100;
      const numericValue = parseFloat(sub.value.replace(',', '.'));
      if (!isNaN(numericValue)) {
        totalWeight += weight;
        weightedSum += numericValue * weight;
      }
    });

    return totalWeight > 0 ? weightedSum.toFixed(2).replace('.', ',') : '';
  };

  const calculateWeightedAverage = (): string => {
    let totalWeightedScore = 0;
    let totalWeight = 0;

    values.forEach((value) => {
      const weight = value.weight / 100;
      const numericValue = parseFloat(
        value.subValues.length > 0 ? calculateParentValue(values.indexOf(value)).replace(',', '.') : value.value.replace(',', '.')
      );

      if (!isNaN(numericValue)) {
        totalWeightedScore += numericValue * weight;
        totalWeight += weight;
      }
    });

    if (totalWeight === 0) return '0';
    const result = totalWeightedScore / totalWeight;

    return result.toFixed(result % 1 === 0 ? 0 : Math.min(3, result.toString().split('.')[1]?.length || 3)).replace('.', ',');
  };

  // Compact encoding and decoding
  const encodeData = (data: Value[]): string => {
    return btoa(JSON.stringify(data));
  };

  const decodeData = (encodedString: string): Value[] => {
    try {
      return JSON.parse(atob(encodedString));
    } catch {
      throw new Error('Invalid data format');
    }
  };

  // Export functionality
  const handleExport = () => {
    const serializedData = encodeData(values);
    navigator.clipboard.writeText(serializedData).then(() => {
      setClipboardCopied(true);
    });
  };

  const resetClipboardStatus = () => {
    setClipboardCopied(false);
  };

  // Import functionality
  const handleImport = () => {
    try {
      const importedValues: Value[] = decodeData(importText);
      setValues(importedValues);
      setImportDialogOpen(false);
    } catch (error) {
      alert('Invalid input data. Please paste a valid export string.');
    }
  };

  // Reset clipboard status when values change
  useEffect(resetClipboardStatus, [values]);

  return (
    <Box p={2}>
      <Grid container alignItems="center" justifyContent="space-between" sx={{mb:2}}>
        <Grid item>
          <Typography variant="h5">Weighted Average Calculator</Typography>
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item>
              <Button
                variant={clipboardCopied ? 'outlined' : 'contained'}
                startIcon={<FileCopy />}
                onClick={handleExport}
              >
                {clipboardCopied ? 'Copied' : 'Export'}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                startIcon={<Upload />}
                onClick={() => setImportDialogOpen(true)}
              >
                Import
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {values.map((value, index) => (
        <Box key={index} mb={3} p={2} border={1} borderColor="grey.300" borderRadius={2}>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={3}>
              <TextField
                label="Value"
                type="text"
                value={value.subValues.length > 0 ? calculateParentValue(index) : value.value}
                disabled={value.subValues.length > 0}
                onChange={(e) => handleChange(index, 'value', e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                label="Weight (%)"
                type="number"
                value={value.weight}
                onChange={(e) => handleChange(index, 'weight', parseInt(e.target.value))}
                fullWidth
              />
            </Grid>
            <Grid item xs={2}>
              <Slider
                value={value.weight}
                onChange={(e, newValue) => handleChange(index, 'weight', newValue as number)}
                step={1}
                min={0}
                max={100}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="Name"
                fullWidth
                value={value.name}
                onChange={(e) => handleChange(index, 'name', e.target.value)}
              />
            </Grid>
            <Grid item xs={2}>
              <IconButton color="error" onClick={() => removeValue(index)}>
                <Delete />
              </IconButton>
            </Grid>
          </Grid>
          {value.subValues.map((sub, subIndex) => (
            <Box key={subIndex} mt={1} pl={4}>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={3}>
                  <TextField
                    label="Value"
                    type="text"
                    value={sub.value}
                    onChange={(e) => handleSubChange(index, subIndex, 'value', e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    label="Weight (%)"
                    type="number"
                    value={sub.weight}
                    onChange={(e) => handleSubChange(index, subIndex, 'weight', parseInt(e.target.value))}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={2}>
                  <Slider
                    value={sub.weight}
                    onChange={(e, newValue) => handleSubChange(index, subIndex, 'weight', newValue as number)}
                    step={1}
                    min={0}
                    max={100}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Name"
                    fullWidth
                    value={sub.name}
                    onChange={(e) => handleSubChange(index, subIndex, 'name', e.target.value)}
                  />
                </Grid>
                <Grid item xs={2}>
                  <IconButton color="error" onClick={() => removeSubValue(index, subIndex)}>
                    <Delete />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
          ))}
          <Box pl={4} mt={1}>
            <Button variant="outlined" startIcon={<Add />} onClick={() => addSubValue(index)}>
              Sub-Value
            </Button>
          </Box>
        </Box>
      ))}
      <Button
        variant="contained"
        color="primary"
        onClick={addValue}
        startIcon={<Add />}
        sx={{ mt: 2 }}
      >
        Value
      </Button>
      {warning && (
        <Typography variant="body2" color="error" mt={2}>
          {warning}
        </Typography>
      )}
      <Box mt={4}>
        <Typography variant="h6">Weighted Average: {calculateWeightedAverage()}</Typography>
      </Box>

      {/* Import Dialog */}
      <Dialog
        open={importDialogOpen}
        onClose={() => setImportDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Import Values</DialogTitle>
        <DialogContent style={{ paddingTop: '16px', paddingLeft: '24px', paddingRight: '24px', paddingBottom: '24px' }}>
          <TextField
            label="Paste Export String"
            multiline
            rows={6}
            value={importText}
            onChange={(e) => setImportText(e.target.value)}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setImportDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleImport} variant="contained">
            Load
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Calculator;