import { Dispatch } from "@reduxjs/toolkit";
import { UserDto } from "common/dto/payload/UserDto";
import { SuccessResponseDto } from "common/dto/response/SuccessResopnseDto";
import { setUser } from "common/store/reducers/userReducer";

// Utility function to get CSRF token from cookies
export async function getCsrfToken() {
    try {
        const response = await fetch('/api/csrf-token', {
            method: 'GET',
            credentials: 'include'
        });

        if (!response.ok) {
            throw new Error('Failed to fetch CSRF token');
        }
        const data = await response.json();
        return data.token;

    } catch (error) {
        console.error('Error fetching CSRF token:', error);
        return '';
    }
};

export async function isAuthenticated() {
    try {
        const response = await fetch('/api/auth/check-auth', {
            method: 'GET',
            credentials: 'include'
        });

        if (response.ok) {
            return true;
        }
        return false

    } catch (error) {
        console.error('Error fetching authentication state:', error);
        return false;
    }
}

export const fetchUserData = async (dispatch: Dispatch) => {
    try {
        const response = await fetch('/api/account/settings', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include'
        });
        const data: SuccessResponseDto = await response.json();
        const newUser: UserDto = data.payload;

        dispatch(setUser(newUser));

        return true;

    } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
        return false;
    }
};